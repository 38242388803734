define('acif/index', ['exports', './utils', './utils/get', './automaton', './ci', './ci/ijsf-adapter', './automaton/utils/get-active-automatons', './utils/logger', './environment/get-env-var', './utils/mixins'], function (exports, _utils, _get, _automaton, _ci, _ijsfAdapter, _getActiveAutomatons, _logger, _getEnvVar) {
  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var CI_STORAGE_KEY = 'acif-ci';

    var toolbox = (0, _getEnvVar2.default)('nuance-toolbox', { persist: true });

    if (toolbox) {
      var doc = parent.document;
      var url = toolbox === 'local' ? 'http://localhost:4204/index.js' : 'https://agvsptest01.touchcommerce.com/toolbox/index.js';

      doc.body.appendChild(doc.createElement('script')).src = url;
    }

    _.extend(ACIF, {
      VERSION: 'release-5.92.0 -- 824d598',
      frame: window,
      Utils: _utils2.default,
      _jsonpCallbacks: {},
      $: $,
      _: _,
      automatons: {},
      loaded: {},
      loadedStack: [],
      IJSF: _ijsfAdapter2.default,
      onStorageReady: new $.Deferred(),
      onConfigsReady: new $.Deferred(),
      ciPanelAutomatonMap: {}
    });

    _ijsfAdapter2.default.logDiagnosticInfoToServer('Initializing');

    ACIF.onConfigsReady.then(function () {
      _ijsfAdapter2.default.logDiagnosticInfoToServer('onConfigsReady Fired');
    });

    registerEventListeners();

    function registerEventListeners() {
      // EVM (event manager) is declared in tcFramework
      ACIF.EVM = ACIF.EVM || { queue: [] };

      var events = {
        onStorageReady: onStorageReady,
        onSkinLoaded: onSkinLoaded,
        onLoadPostChatSurvey: onLoadPostChatSurvey,
        onChatClosed: _ci2.default.listeners.onChatClosed,
        onAgentMsg: _ci2.default.listeners.onAgentMsg,
        onAgentDataPass: _ci2.default.listeners.onAgentDataPass
      };

      _.extend(ACIF.EVM, events);

      _ijsfAdapter2.default.registerIJSFListeners(events);

      // Process the events queue (events fired before ACIF is loaded)
      ACIF.EVM.queue.forEach(function (_ref) {
        var event = _ref.event,
            args = _ref.args;

        if (typeof events[event] === 'function') {
          events[event].apply(null, args);
        }
      });
    }

    function onStorageReady() {
      _ijsfAdapter2.default.logDiagnosticInfoToServer('onStorageReady fired');

      var ciStorage = inQ.Storage.peek(CI_STORAGE_KEY, 'engagement');
      // If we already persisted to localStorage the ci panel dimenions,
      // then use those persisted values. Otherwise, allow all the
      // panel dimensions to default to zero, as undefined values for
      // the panel dimensions cause rendering issues in the chat skin
      ACIF.ci = ciStorage || ACIF.ci;
      ACIF.onStorageReady.resolve();
    }

    function onSkinLoaded() {
      _ijsfAdapter2.default.logDiagnosticInfoToServer('onSkinLoaded fired');

      $.when(ACIF.onStorageReady, ACIF.onConfigsReady).then(prepareAutomatonParamsForCI).catch(_logger.logError);
    }

    // The following four lines should be removed when ACIF becomes part of Nuance Program Rules TODO
    if (!ACIF.isLoadedNatively) {
      var isCanvasLoadedBeforeACIF = _ci2.default.findInSkin('div[id$="acifCenter"]').length || _ci2.default.findInSkin('div[data-acif-v2-frame]').length;
      if (isCanvasLoadedBeforeACIF) {
        onSkinLoaded();
      }
    }

    // Triggered each time a CI is rendered, regardless of whether the originating
    // rule has any automatons to be loaded. Will reload automatons that were
    // previously started by a chat agent or another automaton.
    function prepareAutomatonParamsForCI() {
      _ijsfAdapter2.default.logDiagnosticInfoToServer('prepareAutomatonParamsForCI fired');

      var businessRuleAutomatonParams = getNotCompletedBusinessRuleAutomatonParams();
      var startedBy = getRuleStartedBy();
      var alreadyActiveAutomatonParams = (0, _getActiveAutomatons2.default)();

      ACIF.$skin = _ci2.default.getSkin().$skin;

      alreadyActiveAutomatonParams.forEach(function (automaton) {
        ACIF.loadAutomaton(automaton.params, automaton.startedBy, null, { initializePanel: false });
      });

      if (_ijsfAdapter2.default.isChatConnected()) return;

      businessRuleAutomatonParams.filter(function (rule) {
        return !rule.shouldSuppress;
      }).forEach(function (params) {
        ACIF.loadAutomaton(params, startedBy);
      });
    }

    ACIF.loadAutomaton = function (params, startedBy, automatonDataMap, ciOptions) {

      ciOptions = ciOptions || {};

      if (typeof startedBy !== 'object') {
        throw _utils2.default.throwError('loadAutomaton requires a `startedBy` object to be passed. Received: ' + startedBy);
      }

      var storageKey = getAutomatonStorageKey(params, startedBy);
      var isAlreadyLoaded = !!this.loaded[storageKey];

      if (isAlreadyLoaded) return;

      var options = getLaunchParameters(params, startedBy, automatonDataMap);

      var automaton = _automaton2.default.load(options);
      automaton.storageKey = storageKey;

      if (options.context === 'ci') {

        var existingAutomaton = ACIF.ciPanelAutomatonMap[options.panel];

        if (existingAutomaton) {
          existingAutomaton.close();
        }

        if (ciOptions.initializePanel === false) {
          _ci2.default.reinstatePanel(options.panel);
        } else {
          var automatonSettings = automaton.getRecord(options.automatonId, 'automaton');
          _ci2.default.initializePanel(options, automatonSettings);
        }
      }

      this.automatons.active = automaton;
      this.automatons['a' + automaton.id] = automaton;
      this.loaded[storageKey] = true;
      this.loadedStack.push(storageKey);

      if (options.context === 'ci') {
        var panelId = options.panel;
        ACIF.ciPanelAutomatonMap[panelId] = automaton;
      }

      ACIF.persistLoadedAutomatons();

      _ijsfAdapter2.default.logDiagnosticInfoToServer('loadAutomatonID: ' + automaton.id);

      return automaton;
    };

    ACIF.closeAutomaton = function (params) {
      params = params || {};

      var _params = params,
          automatonId = _params.automatonID,
          location = _params.location,
          context = _params.context;

      var automaton = ACIF.getAutomatonById(automatonId);

      if (!automaton) {
        _utils2.default.console('Could not find automaton \'' + automatonId + '\' to close.', 'error');
        _ijsfAdapter2.default.sendDatapassMessage({ Warning: 'Agent-pushed content ID ' + automatonId + ' is not available to close.' });
        return;
      }

      var dependents = automaton.Data.get('dependents');
      if (dependents) {
        dependents.forEach(function (dependent) {
          var _JSON$parse = JSON.parse(dependent),
              dependentParams = _JSON$parse.params;

          ACIF.closeAutomaton(dependentParams);
        });
        automaton.Data.save('dependents', []);
      }

      var startedBy = automaton.options.startedBy;
      var storageKey = getAutomatonStorageKey(params, startedBy);

      this.loadedStack.splice(this.loadedStack.indexOf(storageKey), 1);

      automaton.Data.CRM.emptyStorage();
      automaton.View.Exit.unload(params);

      this.loaded[storageKey] = false;

      if (context === 'ci') {
        var panelId = _ci2.default.toPanelName(location);
        ACIF.ciPanelAutomatonMap[panelId] = null;
      }

      ACIF.persistLoadedAutomatons();

      return automaton;
    };

    ACIF.getAutomatonById = function (automatonId) {
      return this.automatons['a' + automatonId];
    };

    function onLoadPostChatSurvey(evt) {
      // wrap in setTimeout so we can be sure that the CI is
      // truly remaining open when we attempt to inject
      // the survey into the ID.
      setTimeout(function () {
        _onLoadPostChatSurvey(evt);
      });
    }

    function _onLoadPostChatSurvey(evt) {
      var skin = _ci2.default.getSkin();
      var skinIsVisible = skin.$skin && skin.$skin.is(':visible');

      if (!skinIsVisible) return;

      var automatonID = evt.surveyId,
          _evt$context = evt.context,
          context = _evt$context === undefined ? 'ci' : _evt$context,
          _evt$location = evt.location,
          location = _evt$location === undefined ? 'center' : _evt$location;


      var startedBy = {
        type: 'survey',
        id: 'survey'
      };

      var params = {
        automatonID: automatonID,
        context: context,
        location: location
      };

      _ijsfAdapter2.default.hideLayer('thankYou');

      // close all active automatons when post chat survey is loaded
      var activeAutomatons = (0, _getActiveAutomatons2.default)();
      activeAutomatons.forEach(function () {
        var automatonReference = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var automatonId = (0, _get2.default)(automatonReference, 'params.automatonID');
        var automaton = ACIF.getAutomatonById(automatonId);

        if (!automaton) return;

        automaton.close();
      });

      ACIF.loadAutomaton(params, startedBy);
    }

    ACIF.persistLoadedAutomatons = _.debounce(function () {
      var persistedAutomatons = inQ.Storage.peek('acif-loaded-automatons', 'engagement');
      _.defaults(ACIF.loaded, persistedAutomatons);
      inQ.Storage.set('acif-loaded-automatons', ACIF.loaded, 'engagement');
      inQ.Storage.set(CI_STORAGE_KEY, ACIF.ci, 'engagement');
    }, 100);

    function getAlreadyCompletedAutomatons() {
      var automatons = inQ.Storage.peek('acif-loaded-automatons', 'engagement') || {};
      // persisted automatons that are set to 'false'
      var activeAutomatons = Object.keys(automatons).filter(function (key) {
        return automatons[key] === false;
      });
      return activeAutomatons.map(function (key) {
        return JSON.parse(key);
      });
    }

    // this method will filter already completed business rule automatons
    function getNotCompletedBusinessRuleAutomatonParams() {
      var businessRuleAutomatonParams = _ci2.default.getBusinessRuleAutomatonParams();
      var alreadyCompletedAutomatonParams = getAlreadyCompletedAutomatons();

      return businessRuleAutomatonParams.filter(function (brParams) {
        var shouldKeepParams = true;
        alreadyCompletedAutomatonParams.forEach(function (completed) {
          var isSameAutomaton = brParams.automatonID === completed.params.automatonID;
          var isSameContext = brParams.context === completed.params.context;
          var isSameLocation = brParams.location === completed.params.location;

          if (isSameAutomaton && isSameContext && isSameLocation) {
            shouldKeepParams = false;
          }
        });

        return shouldKeepParams;
      });
    }

    function getLaunchParameters(params, startedBy, automatonDataMap) {
      var automatonID = params.automatonID,
          context = params.context,
          location = params.location,
          _params$datapass = params.datapass,
          datapass = _params$datapass === undefined ? {} : _params$datapass;

      var panel = location || null;

      if (!automatonID) {
        _utils2.default.throwError('\'' + automatonID + '\' is not a valid automaton ID.  Likely configuration issue in the business rules.');
      }

      if (context === 'ci') {
        panel = _ci2.default.toPanelName(location);
      }

      var stage = _ci2.default.getStage(context, panel);

      stage.$container.empty();

      return {
        automatonId: automatonID,
        context: context,
        location: location,
        panel: panel,
        automatonDataMap: automatonDataMap || _ijsfAdapter2.default.getAutomatonDataMap(),
        // the automatonDataMap will have been merged into the `datapass`
        // object listed below (see getBusinessRuleAutomatonParams)
        datapass: datapass,
        startedBy: startedBy,
        top: parent,
        frame: stage.frame,
        document: stage.document,
        $container: stage.$container,
        $skin: stage.$skin
      };
    }

    function getAutomatonStorageKey(rawParams, startedBy) {
      return JSON.stringify({
        params: {
          automatonID: rawParams.automatonID,
          context: rawParams.context,
          location: rawParams.location
        },
        startedBy: startedBy
      });
    }

    function getRuleStartedBy() {
      var activeRule = _ijsfAdapter2.default.getActiveRule() || {};

      return {
        type: 'br',
        id: activeRule.id,
        name: activeRule.name
      };
    }
  };

  var _utils2 = _interopRequireDefault(_utils);

  var _get2 = _interopRequireDefault(_get);

  var _automaton2 = _interopRequireDefault(_automaton);

  var _ci2 = _interopRequireDefault(_ci);

  var _ijsfAdapter2 = _interopRequireDefault(_ijsfAdapter);

  var _getActiveAutomatons2 = _interopRequireDefault(_getActiveAutomatons);

  var _getEnvVar2 = _interopRequireDefault(_getEnvVar);

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
});